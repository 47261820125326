<template>
  <v-container style="max-width: 1300px">
    <v-row no-gutters>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <v-card class="pa-4 pb-8">
          <v-row dense no-gutters class="fill-height">
            <v-tabs
              v-model="tab"
              class="custom-tab-design"
              background-color="transparent"
            >

              <v-tab
                :key="0"
                data-cy="tab-visuals"
                class="ml-2"
              >
                {{ $lang.labels.visuals }}
              </v-tab>

              <v-tab
                :key="1"
                data-cy="tab-display"
                class="ml-2"
              >
                {{ $lang.labels.display }}
              </v-tab>
              <v-tab
                :key="2"
                data-cy="tab-admin"
                class="ml-2"
              >
                {{ $lang.labels.passwordChange }}
              </v-tab>
              <v-tab
                :key="3"
                data-cy="tab-admin"
                class="ml-2"
              >
                {{ $lang.labels.twoFaAuth }}
              </v-tab>
              <v-tab
                :key="4"
                data-cy="tab-user"
                class="ml-2"
              >
                {{ $lang.labels.user }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="fill-height" style="width: 100%">
              <v-tab-item
                :key="0"
              >
                <v-row dense no-gutters class="pt-4">
                  <v-col cols="12">
                    <h5 class="text-h5 ml-2">{{ $lang.labels.theme }}</h5>
                  </v-col>
                  <template v-for="(theme, i) in themes">
                    <v-btn
                      :key="i"
                      :color="formData.visuals.selectedTheme === theme.value ? 'primary' : 'grey'"
                      :class="{'button-default-width': true, 'ml-2': i !== 0 }"
                      class="mt-2 ml-2"
                      @click="formData.visuals.selectedTheme = theme.value; changeTheme(theme.value === 'dark')"
                    >
                      <v-icon class="mr-1">{{ theme.icon }}</v-icon>
                      {{ theme.text }}
                    </v-btn>
                  </template>
                </v-row>
                <v-row dense no-gutters class="pt-4">
                  <v-col cols="12">
                    <h5 class="text-h5 ml-2">{{ $lang.labels.colors }}</h5>
                  </v-col>
                  <template v-for="(color, i) in colorSchemes">
                    <v-btn
                      :key="i + 1000"
                      :color="formData.visuals.selectedColorScheme === color.value ? 'primary' : 'grey'"
                      height="48"
                      class="ml-2 mt-2"
                      @click="formData.visuals.selectedColorScheme = color.value; changeColors(color.value)"
                    >
                      {{ color.text }}:
                      <v-icon class="ml-1" style="border: 1px solid white; border-radius: 50%" :color="color.colorPrimary">mdi-circle</v-icon>
                      <v-icon class="ml-1" style="border: 1px solid white; border-radius: 50%" :color="color.colorAccent">mdi-circle</v-icon>
                      <v-icon class="ml-1" style="border: 1px solid white; border-radius: 50%" :color="color.colorSecondary">mdi-circle</v-icon>
                    </v-btn>
                  </template>
                </v-row>
              </v-tab-item>
              <v-tab-item
                :key="1"
                style="width: 100%"
              >
                <v-row dense no-gutters class="pt-4">
                  <v-col cols="12" md="3">
                    <v-row dense no-gutters>
                      <v-col cols="12">
                        <h5 class="text-h5 ml-2">{{ $lang.labels.show }}</h5>
                      </v-col>
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showCreatedOn"
                          data-cy="show-create-on-switch"
                          :label="$lang.labels.createdOn"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters class="pt-2">
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showModifiedOn"
                          data-cy="show-create-on-switch"
                          :label="$lang.labels.modifiedOn"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters class="pt-2">
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showId"
                          data-cy="show-create-on-switch"
                          :label="$lang.labels.id"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters class="pt-2">
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showComments"
                          data-cy="show-create-on-switch"
                          :label="$lang.labels.showComments"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters class="pt-2">
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showCopyPaste"
                          data-cy="show-copy-paste-switch"
                          :label="$lang.labels.copyPaste"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-row dense no-gutters>
                      <v-col cols="12">
                        <h5 class="text-h5 ml-2">{{ $lang.labels.processDebugger }}</h5>
                      </v-col>
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showFinishedOn"
                          data-cy="show-show-finished-on-switch"
                          :label="$lang.labels.showFinishedOn"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters class="pt-2">
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showStepType"
                          data-cy="show-show-finished-on-switch"
                          :label="$lang.labels.showStepType"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item
                :key="2"
                style="width: 100%"
              >
                <v-row dense no-gutters class="pt-4">
                  <v-col cols="12" md="4">
                    <v-row dense no-gutters>
                      <v-col cols="12">
                        <h5 class="text-h5 ml-2 mb-2">{{ $lang.labels.changePassword }}</h5>
                      </v-col>
                      <v-col cols="12" class="pl-2">
                        <v-text-field
                          v-model="oldPassword"
                          :type="oldPasswordEye ? 'text' : 'password'"
                          :append-icon="oldPasswordEye ? 'mdi-eye-off' : 'mdi-eye'"
                          :label="$lang.labels.oldPassword"
                          outlined
                          dense
                          :rules="oldPasswordRules"
                          @click:append="oldPasswordEye = !oldPasswordEye"
                          @keyup.enter="submit()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters>
                      <v-col cols="12" class="pl-2">
                        <v-text-field
                          v-model="newPassword"
                          :type="newPasswordEye ? 'text' : 'password'"
                          :append-icon="newPasswordEye ? 'mdi-eye-off' : 'mdi-eye'"
                          :label="$lang.labels.newPassword"
                          outlined
                          dense
                          :rules="newPasswordRules"
                          @click:append="newPasswordEye = !newPasswordEye"
                          @keyup.enter="submit()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters>
                      <v-col cols="12" class="pl-2">
                        <v-text-field
                          v-model="confirmPassword"
                          :type="confirmPasswordEye ? 'text' : 'password'"
                          :append-icon="confirmPasswordEye ? 'mdi-eye-off' : 'mdi-eye'"
                          :label="$lang.labels.confirmPassword"
                          outlined
                          dense
                          :rules="confirmPasswordRules"
                          @click:append="confirmPasswordEye = !confirmPasswordEye"
                          @keyup.enter="submit()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters>
                      <v-col cols="12" class="pl-2">
                        <v-btn
                          block
                          class="accent color-accent-text"
                          :disabled="isDisabled()"
                          :loading="loadingPassword"
                          @click="changePassword()"
                        >{{ $lang.labels.setPassword }}</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item
                :key="3"
              >
                <v-row dense no-gutters class="pt-4">
                  <v-col cols="12" md="6" class="pl-2">
                    <v-switch
                      v-model="twoFactorAuth"
                      data-cy="twoFactorAuth-switch"
                      :label="$lang.labels.twoFaAuthentication"
                      color="success"
                      hide-details
                      dense
                    ></v-switch>
                    <v-text-field
                      v-if="twoFactorAuthQrCode"
                      ref="otpRef"
                      v-model="otpCode"
                      :label="$lang.labels.otp"
                      outlined
                      hide-details
                      dense
                      class="mt-4"
                      style="width: 220px"
                      :rules="[v => !!v || $lang.errors.required]"
                      @keyup.enter="enableTwoFactorAuth()"
                    ></v-text-field>
                    <v-btn
                      v-if="twoFactorAuthQrCode"
                      class="mt-2"
                      color="primary"
                      style="width: 220px"
                      :disabled="!otpCode || otpCode.length < 6 || otpCode.length > 6"
                      outlined
                      @click="enableTwoFactorAuth()"
                    >{{ $lang.labels.submitOtp }}</v-btn>
                    <div class="d-flex mt-2">
                      <p v-if="twoFactorAuthSuccess" class="color-primary">{{ twoFactorAuthSuccess }}</p>
                      <p v-if="twoFactorAuthErr" class="color-error">{{ twoFactorAuthErr }}</p>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" class="pl-2">
                    <p v-if="twoFactorAuthQrCode" class="mt-3 mb-2">{{ $lang.labels.scanQrCode }}</p>
                    <img v-if="twoFactorAuthQrCode" :src="`data:image/jpeg;base64, ${twoFactorAuthQrCode}`" alt="QR Code">
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item
                :key="4"
              >
                <v-row dense no-gutters class="pt-4">
                  <v-col v-if="user" cols="12" class="pl-2">
                    <p>{{ $lang.labels.id }}: <span class="color-primary">{{ user.id }}</span></p>
                    <p>{{ $lang.labels.name }}: <span class="color-primary clickable-simple" @click="openUser()">{{ user.name }}</span>
                      <span :class="{ 'clickable-simple': isUserSuperAdminOrUserCreator, 'pl-1 color-primary': true }" @click="isUserSuperAdminOrUserCreator ? openUser() : ''"><v-icon v-if="isUserSuperAdminOrUserCreator" color="primary">mdi-open-in-new</v-icon></span>
                    </p>
                    <p>{{ $lang.labels.createdOn }}: <span class="color-primary">{{ user.createdOn | formatDateTimePrecise }}</span></p>
                    <p>{{ $lang.labels.modifiedOn }}: <span class="color-primary">{{ user.modifiedOn | formatDateTimePrecise }}</span></p>
                    <v-divider class="mb-4"></v-divider>
                    <div class="d-flex justify-end">
                      <v-text-field
                        v-if="user && user.roles && user.roles.length > 0"
                        v-model="filterRoles"
                        :label="$lang.labels.filterRoles"
                        outlined
                        hide-details
                        class="mb-2"
                        dense
                        style="width: 220px; max-width: 220px"
                        clearable
                      ></v-text-field>
                    </div>
                    <p>{{ $lang.labels.roles }}: <span class="color-primary">{{ filterRoles ? filterRolesMethod().join(', ') : user.roles.map(x => x.name).join(', ') }}</span></p>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import {
  getAllUserSettingsUsingGET as getProfile,
  saveUserSettingsUsingPOST as saveProfile,
  updatePasswordUsingPUT as updatePassword,
  getTwoFactorAuthQRCodeUsingGET as getTwoFactorAuthQRCode,
  twoFactorAuthEnableUsingPOST as twoFactorAuthEnable,
  twoFactorAuthDisableUsingDELETE as twoFactorAuthDisable
} from '@/utils/api'
import colorsConfig from '@/configs/colorSchemes'
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      user: localStorage.userData ? JSON.parse(localStorage.userData) : null,
      filterRoles: '',
      lock: true,
      tab: 0,
      loadingPassword: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      formData: {
        general: {
          selectedLanguage: 'en'
        },
        visuals: {
          selectedTheme: 'light',
          selectedColorScheme: 'default'
        },
        display: {
          showCreatedOn: true,
          showModifiedOn: true,
          showId: true,
          showComments: false,
          showCopyPaste: false,
          showFinishedOn: false,
          showStepType: false
        }
      },
      themes: [
        {
          value: 'light',
          text: this.$lang.labels.light,
          icon: 'mdi-weather-sunny'
        },
        {
          value: 'dark',
          text: this.$lang.labels.dark,
          icon: 'mdi-moon-waning-crescent'
        }
      ],
      colorSchemes: [
        {
          colorPrimary: colorsConfig.default.dark.primary,
          colorAccent: colorsConfig.default.dark.accent,
          colorSecondary: colorsConfig.default.dark.secondary,
          text: 'Default',
          value: 'default'
        },
        {
          colorPrimary: colorsConfig.goldenrod.dark.primary,
          colorAccent: colorsConfig.goldenrod.dark.accent,
          colorSecondary: colorsConfig.goldenrod.dark.secondary,
          text: 'Goldenrod',
          value: 'goldenrod'
        },
        {
          colorPrimary: colorsConfig.magenta.dark.primary,
          colorAccent: colorsConfig.magenta.dark.accent,
          colorSecondary: colorsConfig.magenta.dark.secondary,
          text: 'Magenta',
          value: 'magenta'
        },
        {
          colorPrimary: colorsConfig.violet.dark.primary,
          colorAccent: colorsConfig.violet.dark.accent,
          colorSecondary: colorsConfig.violet.dark.secondary,
          text: 'Violet',
          value: 'violet'
        },
        {
          colorPrimary: colorsConfig.greenCrayola.dark.primary,
          colorAccent: colorsConfig.greenCrayola.dark.accent,
          colorSecondary: colorsConfig.greenCrayola.dark.secondary,
          text: 'Green Crayola',
          value: 'greenCrayola'
        },
        {
          colorPrimary: colorsConfig.blue.dark.primary,
          colorAccent: colorsConfig.blue.dark.accent,
          colorSecondary: colorsConfig.blue.dark.secondary,
          text: 'Blue',
          value: 'blue'
        },
        {
          colorPrimary: colorsConfig.simple.dark.primary,
          colorAccent: colorsConfig.simple.dark.accent,
          colorSecondary: colorsConfig.simple.dark.secondary,
          text: 'Simple',
          value: 'simple'
        }
      ],
      loading: false,
      oldPasswordRules: [
        (v) => !!v || this.$lang.errors.required,
        (v) => (v && v.length >= 6) || this.$lang.errors.passwordLength
      ],
      newPasswordRules: [
        (v) => !!v || this.$lang.errors.required,
        (v) => (v && v.length >= 6) || this.$lang.errors.passwordLength,
        (v) => (v !== this.oldPassword) || this.$lang.errors.passwordsMatch
      ],
      confirmPasswordRules: [
        (v) => !!v || this.$lang.errors.required,
        (v) => (v && v.length >= 6) || this.$lang.errors.passwordLength,
        (v) => (v === this.newPassword) || this.$lang.errors.passwordsDontMatch
      ],
      oldPasswordEye: false,
      newPasswordEye: false,
      confirmPasswordEye: false,
      twoFactorAuth: false,
      twoFactorAuthQrCode: '',
      otpCode: '',
      twoFactorAuthSuccess: '',
      twoFactorAuthErr: ''
    }
  },
  computed: {
    ...mapState('app', [
      'userRoles'
    ]),
    isUserSuperAdminOrUserCreator() {
      return this.userRoles.map((x) => x.name).includes('SUPER_USER') || this.userRoles.map((x) => x.name).includes('USER_CREATOR')
    }
  },
  watch: {
    twoFactorAuth: {
      handler(val) {
        if (val) {
          if (!this.lock) this.getQrCode()
        } else {
          if (!this.lock) this.disableTwoFactorAuth()
        }
      },
      immediate: true
    },
    'formData.display': {
      handler() {
        this.submit()
      },
      deep: true
    },
    'formData.visuals': {
      handler() {
        this.submit()
      },
      deep: true
    }
  },
  created() {
    this.fetchUserProfile()

    const userData = localStorage.getItem('userData')

    if (userData) {
      this.twoFactorAuth = JSON.parse(userData).twoFactorAuth
    }

    setTimeout(() => {
      this.lock = false
    }, 100)
  },
  methods: {
    ...mapActions('app', [
      'globalTheme',
      'userSettingsSave',
      'addSnackbar'
    ]),
    openUser() {
      const url = this.$router.resolve({
        name: 'userEdit',
        params: { id: this.user.id }
      })

      window.open(url.href, '_blank')
    },
    filterRolesMethod() {
      return this.user.roles.filter((x) => x.name.toLowerCase().includes(this.filterRoles.toLowerCase())).map((x) => x.name)
    },
    saveLocalStorageUserAuthData(twoFaData) {
      const userData = localStorage.getItem('userData')

      if (userData) {
        const userDataParsed = JSON.parse(userData)

        userDataParsed.twoFactorAuth = twoFaData
        localStorage.setItem('userData', JSON.stringify(userDataParsed))
      }
    },
    enableTwoFactorAuth() {
      twoFactorAuthEnable({ body: { otpCode: this.otpCode } })
        .then((res) => {
          if (res?.data?.status === 'SUCCESS') {
            this.saveLocalStorageUserAuthData(true)
            this.twoFactorAuthSuccess = this.$lang.success.twoFactorAuthEnabled

            setTimeout(() => {
              this.twoFactorAuthSuccess = ''
              this.otpCode = ''
              this.twoFactorAuthQrCode = ''
            }, 6000)

            return
          }

          this.twoFactorAuthErr = res.response.data.statusText

          setTimeout(() => {
            this.twoFactorAuthErr = ''
          }, 6000)

        })
        .catch((err) => {
          console.log(err)
        })
    },
    disableTwoFactorAuth() {
      twoFactorAuthDisable()
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            this.twoFactorAuthQrCode = ''
            this.otpCode = ''
            this.saveLocalStorageUserAuthData(false)

            this.twoFactorAuthSuccess = this.$lang.success.twoFactorAuthDisabled

            setTimeout(() => {
              this.twoFactorAuthSuccess = ''
            }, 6000)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getQrCode() {
      getTwoFactorAuthQRCode()
        .then((res) => {
          this.twoFactorAuthQrCode = res.data.data.qrCode

          setTimeout(() => {
            this.$refs.otpRef.focus()
          }, 100)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    isDisabled() {
      return this.loading || this.loadingPassword || this.oldPassword === '' || this.newPassword === '' || this.newPassword.length < 6 || this.oldPassword.length < 6 || this.oldPassword === this.newPassword || this.newPassword !== this.confirmPassword
    },
    fetchUserProfile() {
      getProfile()
        .then((res) => {
          const tempData = res.data.data

          if (!tempData.display) {
            tempData.display = {
              showCreatedOn: true,
              showModifiedOn: true,
              showId: true,
              showComments: false,
              showCopyPaste: false,
              showFinishedOn: false,
              showStepType: false
            }
          }

          this.userSettingsSave(tempData)

          if (!tempData.visuals) {
            tempData.visuals = {
              selectedTheme: 'light',
              selectedColorScheme: 'default'
            }
            if (localStorage.selectedTheme) {
              tempData.visuals.selectedTheme = JSON.parse(localStorage.selectedTheme) ? 'dark' : 'light'
            }
          }
          if (!tempData.general) {
            tempData.general = {
              selectedLanguage: 'en'
            }
            if (localStorage.selectedLanguage) {
              tempData.general.selectedLanguage = localStorage.selectedLanguage
            }
          }
          this.formData = tempData
          this.changeTheme(tempData.visuals.selectedTheme === 'dark')
          this.changeColors(tempData.visuals.selectedColorScheme)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    changeLang(lang) {
      this.$lang.setLang(lang)
      localStorage.setItem('selectedLanguage', lang)
      this.$router.push({ name: this.$route.name, params: { lang } })
    },
    changeTheme(theme) {
      this.$vuetify.theme.dark = theme
      localStorage.setItem('selectedTheme', theme)
      this.globalTheme(theme)
    },
    changeColors(type) {
      const { dark } = colorsConfig[type]
      const { light } = colorsConfig[type]

      if (dark && light) {
        localStorage.setItem('selectedColorScheme', type)

        // set themes
        Object.keys(dark).forEach((i) => {
          this.$vuetify.theme.themes.dark[i] = dark[i]
        })
        Object.keys(light).forEach((i) => {
          this.$vuetify.theme.themes.light[i] = light[i]
        })
      }
    },
    changePassword() {
      this.loadingPassword = true
      updatePassword({ body: { oldPassword: this.oldPassword, newPassword: this.newPassword } })
        .then((res) => {
          if (res?.data?.status === 'SUCCESS') {
            this.addSnackbar({
              message: this.$lang.success.passwordChanged,
              timeout: 5000,
              color: 'success'
            })
            this.loadingPassword = false
            this.oldPassword = ''
            this.newPassword = ''
            this.confirmPassword = ''

            return
          }
          this.loadingPassword = false
          const errorMessage = res.response?.data?.statusText || res.data?.statusText || this.$lang.errors.somethingWentWrong

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })        })
        .catch((err) => {
          console.log(err)
          const errMessage = err.response?.data?.statusText || this.$lang.errors.somethingWentWrong

          this.addSnackbar({
            message: errMessage,
            timeout: 5000,
            color: 'error'
          })
          this.loadingPassword = false

        })
    },
    submit() {
      this.loading = true
      saveProfile({ body: { settings: this.formData } })
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            this.addSnackbar({
              message: this.$lang.success.userSettingsSubmit,
              timeout: 5000,
              color: 'success'
            })
            this.loading = false
          }
          this.loading = false
          this.addSnackbar({
            message: res.data.statusText,
            timeout: 5000,
            color: 'error'
          })
        })
        .catch((err) => {
          console.log(err)
          this.addSnackbar({
            message: this.$lang.errors.somethingWentWrong,
            timeout: 5000,
            color: 'error'
          })
          this.loading = false
        })

    }
  }
}
</script>
<style scoped lang="scss">

</style>
